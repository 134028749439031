import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layout';
import Seo from '../components/seo';
import Bio from '../components/bio';
import SectionHeader from '../components/section-header';
import TimeStampSection from '../components/timestamp-section';
import ProjectSection from '../components/project-section';

function RoadmapPage({ data }) {
  const metaData = data.site.siteMetadata;
  const { author, about, language } = metaData;
    // const { projects } = about;

  return (
    <Layout>
      <Seo title="Roadmap" />
      <Bio author={author} language={language} />
      <SectionHeader title="The DeCent Times Roadmap" />
      <strong>PHASE 1</strong>
      <br></br>
      <b>Curate a selection of multi-topic articles for readers to become acquainted with DeCent Times, share our writers talent, and gain feedback on content. During this time we will also:</b>
      <br></br>
      <br></br>
      - Implement Arweave decentralized storage for all content within the site.
      <br></br>
      - Begin work on web3 based subscriptions for DeCent Times and governance.
      <br></br>
      - Explore community partnerships and expand writing staff.
      <br></br>
      <br></br>
      <strong>PHASE 2</strong>
      <br></br>
      <b>Launch Discord and forums based on the site to gain community engagement. Both platforms will be used to guide the development of our vision of Journalism as a Service (Jaas) as well as informational pieces for the community. At this point in time we will have:</b>
      <br></br>
      <br></br>
      - Finished the framework for our web3 based subscriptions and governance to allow our community to both help support decentralized information and the writers at DeCent Times.
      <br></br>
      - Solidified partnerships with community writers, platforms, and educators within the space 
      <br></br>
      - Begin working on a Jaas portal for writers to connect with clients and provide their skills and services seamlessly through the power of web3 transactions
      <br></br>
      <br></br>
      <strong>PHASE 3</strong>
      <br></br>
      <b>Release our Jaas portal and begin providing journalistic services to our community and clients.</b>
      <br></br>
      <br></br>
      - Continue to provide timely content to our readers
      <br></br>
      - Contract out services through our Jaas portal
      <br></br>
      - Enable our readers to subscribe using cryptocurrency to support our community and it’s work
      <br></br>

      {/* <ProjectSection projects={projects}/> */}
    </Layout>
  );
}

export default RoadmapPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 500, truncate: true)
          frontmatter {
            categories
            title
            date(formatString: "MMMM DD, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }

    site {
      siteMetadata {
        language
        author {
          name
          bio {
            role
            description
            thumbnail
          }
          social {
            github
            linkedIn
            email
          }
        }
      }
    }
  }
`;