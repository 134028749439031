import React from 'react';
import SectionHeader from '../section-header';
import IconButtonBar from '../icon-button-bar';
import './style.scss';

function TimeStampSection({ timestamps }) {
  if (!timestamps || timestamps.length < 2) return null;
  return (
    <div className="timestamp-section">
      <SectionHeader title="Mission" />
      <div className="body">
        {timestamps.map((timestamp, index) =>
          index === 0 ? null : (
            <div>
              DeCent Times aims to provide quality and factual content, on underreported news, covering a wide variety of topics, without a focus on any one specific geographic area. We plan to achieve this  through a number of mediums, starting with a newsletter format, shipped biweekly. At DeCent Times, we believe in starting small and becoming something bigger than the sum of all parts, through healthy community development, meaningful engagement and production of thought provoking content. 
            <br></br>
            <br></br>
            <br></br>
            <SectionHeader title="Our Values" />
            <strong>IMPROVEMENT</strong> - We believe that many facets of our everyday life can be improved, we value any and all ideas that can lead to an increased quality of living. 
            <br></br>
            <hr></hr>
            <br></br>
            <strong>COMMUNITY</strong> - An important part of improvement, though it starts with an individual, ripples out into their local communities. If we strive to continuously  make positive impacts, the long term effects can be profound. 
            <br></br>
            <hr></hr>
            <br></br>
            <strong>EDUCATION</strong> - We have a focus on the cryptocurrency space, as an emerging asset class that is largely misunderstood. As it turns out, the further one goes into research and education, the more varying fields they realize all converge into this new and emerging space.
            <br></br>
            <hr></hr>
            <br></br>
            <strong>GLOBAL</strong> - This cornerstone piggybacks on our idea behind community and can be summed up in four words, "Think global, act local." What this means is our seemingly small personal contributions have lasting implications, even if we cannot see them firsthand or immediately.
            <br></br>
            <hr></hr>
            <br></br>
            </div>
          ),
        )}
      </div>
    </div>
  );
}

export default TimeStampSection;
